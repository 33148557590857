$(() => {
  const select = $('select[name="matter"]');
  select.select2({
    width: '100%',
    placeholder: 'Select',
    allowClear: true,
    ajax: {
      url: '/matters',
      dataType: 'json',
      data: (params) => ({
        query: params.term?.toLowerCase(),
        client_no: $('select[name="client"]').val()
      }),
      processResults: function (data) {
        return {
          results: data.map(matter => ({
            id: matter.MatterNo,
            text: `${matter.MatterDesc} (${matter.MatterNo})`
          }))
        };
      }
    }
  })
})
