// app/javascript/packs/application.js
// //= require jquery3

// global.$ = require("jquery")
// global.jQuery = global.$

import $ from "jquery";

//require("@rails/ujs").start()
// require("turbolinks").start()
//import("turbolinks");
import "@hotwired/turbo-rails"

require("@rails/activestorage").start()

require('select2')
require("select2/dist/css/select2.min.css")

// require('chosen-js')
// require("chosen-js/chosen.css")

require('jstree')
require('jstree/dist/themes/default/style.css')

import "@fortawesome/fontawesome-free/js/all"
import "./stylesheets/application.scss"

require('./client_select')
require('./matter_select')

// import 'search'

import 'sweet-alert-confirm'

require('flatpickr')
global.rangePlugin = require("flatpickr/dist/plugins/rangePlugin");

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
require('flatpickr/dist/plugins/monthSelect/style.css')

require("./nested-forms/addFields");
require("./nested-forms/removeFields");


import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
import Rails from "@rails/ujs";
window.Alpine = Alpine
$(() => {
    Alpine.start();
});

import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';

const chartOptions = {
    plugins: {
        title: {
            display: false
        },
        legend: {
            display: false
        },
        responsive: true
    },
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    tooltips: {
        enabled: false,
    },
    layout: {
        padding: 0,
        margin: 0
    },
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0.3
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            title: false,
            ticks: {
                display: false
            },
            border: {display: false}
        },
        y: {
            grid: {
                display: false
            },
            title: false,
            ticks: {
                display: false
                //suggestedMin: 0,
                //suggestedMax: 10
            },
            border: {display: false}
        }
    }
};


document.addEventListener('alpine:init', () => {
    Alpine.store('accordion', {
        tab: 0
    });

    Alpine.data('accordion', (idx) => ({
        init() {
            this.idx = idx;
        },
        idx: -1,
        handleClick() {
            this.$store.accordion.tab = this.$store.accordion.tab === this.idx ? 0 : this.idx;
        },
        handleRotate() {
            return this.$store.accordion.tab === this.idx ? 'rotate-180' : '';
        },
        handleToggle() {
            return this.$store.accordion.tab === this.idx ? `max-height: ${this.$refs.tab.scrollHeight}px` : '';
        }
    }));
})

document.addEventListener('DOMContentLoaded', function() {
    flatpickr('.date-range-selector', {
        disableMobile: "true",
        "plugins": [new rangePlugin({ input: "#end_date"})]
    });

    flatpickr('.date-month-selector', {
        static: true,
        altInput: true,
        plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "M Y"})]
    });
})

$(document).on("turbo:before-cache", function() {
    $('.select2-dropdown').each(function() {
        if ($(this).data('select2')) {
            $(this).select2('destroy');
        }
    });
    // $('select[name="client"]').select2('destroy');
    // $('select[name="matter"]').select2('destroy');
    // i = null;

    if (document.getElementById('load-spinner') != null) {
        document.getElementById('load-spinner').classList.remove('inline-block');
        document.getElementById('load-spinner').classList.add('hidden');
    }

    if (document.getElementById('load-spinner1') != null) {
        document.getElementById('load-spinner1').classList.remove('inline-block');
        document.getElementById('load-spinner1').classList.add('hidden');
    }
});

document.addEventListener('turbo:before-fetch-request', (evt) => {
    //console.log(evt);
    if (document.getElementById('load-spinner') != null) {
        document.getElementById('load-spinner').classList.remove('hidden');
        document.getElementById('load-spinner').classList.add('inline-block');
    }

    if (evt.detail.url.pathname.includes("search")) {
        $('#results_inner').addClass("animate-pulse opacity-10 transition transform cursor-wait");
    }
});

document.addEventListener('turbo:after-fetch-response', (evt) => {
    if (document.getElementById('load-spinner') != null) {
        document.getElementById('load-spinner').classList.remove('inline-block');
        document.getElementById('load-spinner').classList.add('hidden');
    }

    if (document.getElementById('load-spinner1') != null) {
        document.getElementById('load-spinner1').classList.remove('inline-block');
        document.getElementById('load-spinner1').classList.add('hidden');
    }
});

// Revisit this at later stage
document.addEventListener("turbo:frame-missing", (event) => {
    const { detail: { response, visit } } = event;
    event.preventDefault();
    visit(response.url);
});


document.addEventListener('turbo:frame-load', () => {
    if (document.getElementById('load-spinner') != null) {
        document.getElementById('load-spinner').classList.remove('inline-block');
        document.getElementById('load-spinner').classList.add('hidden');
    }

    if (window.location.pathname === "/settings" && document.getElementById('chart1') != null) {
        var ctx = document.getElementById('chart1').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels), //[1, 2, 1, 3, 5, 4, 7],
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(101, 116, 205, 0.1)",
                        borderColor: "rgba(101, 116, 205, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data) //[1, 2, 1, 3, 5, 4, 7],
                    },
                ],
            },
            options: chartOptions
        });
        //
        var ctx = document.getElementById('chart2').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(246, 109, 155, 0.1)",
                        borderColor: "rgba(246, 109, 155, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data) //[2, 3, 2, 9, 7, 7, 4],
                    },
                ],
            },
            options: chartOptions
        });
        //
        var ctx = document.getElementById('chart3').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(181, 216, 204, 0.1)",
                        borderColor: "rgba(181, 216, 204, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data) // [2, 5, 1, 3, 2, 6, 7],
                    },
                ],
            },
            options: chartOptions
        });
        //
        var ctx = document.getElementById('chart4').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(114, 225, 209, 0.1)",
                        borderColor: "rgba(114, 225, 209, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data) // [2, 5, 1, 3, 2, 6, 7],
                    },
                ],
            },
            options: chartOptions
        });
        //
        var ctx = document.getElementById('chart5').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(246, 153, 63, 0.1)",
                        borderColor: "rgba(246, 153, 63, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data) // [2, 5, 1, 3, 2, 6, 7],
                    },
                ],
            },
            options: chartOptions
        });
        var ctx = document.getElementById('chart6').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(101, 116, 205, 0.1)",
                        borderColor: "rgba(101, 116, 205, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data)
                    },
                ],
            },
            options: chartOptions
        });
        var ctx = document.getElementById('chart7').getContext('2d');
        var chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [
                    {
                        fill: true,
                        backgroundColor: "rgba(101, 116, 205, 0.1)",
                        borderColor: "rgba(101, 116, 205, 0.8)",
                        borderWidth: 2,
                        data: JSON.parse(ctx.canvas.dataset.data)
                    },
                ],
            },
            options: chartOptions
        });
    }

    if (window.location.pathname === "/opportunity_workflow/stats") {
        var ctx = document.getElementById('chart1').getContext('2d');
        var chart = new Chart(ctx, {
            type: 'bar',
            data: JSON.parse(ctx.canvas.dataset.data),
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Number of Opportunities by Close Date'
                    }
                }
            },
        });

        var ctx = document.getElementById('chart2').getContext('2d');
        var chart = new Chart(ctx, {
            type: 'bar',
            data: JSON.parse(ctx.canvas.dataset.data),
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Number of Opportunities by Created Date'
                    }
                }
            },
        });

        var ctx = document.getElementById('chart3').getContext('2d');
        var chart = new Chart(ctx, {
            type: 'bar',
            data: JSON.parse(ctx.canvas.dataset.data),
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Weighted Estimated Closing Revenue'
                    }
                }
            },
        });

        var ctx = document.getElementById('chart4').getContext('2d');
        var chart = new Chart(ctx, {
            type: 'bar',
            data: JSON.parse(ctx.canvas.dataset.data),
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Weighted Estimated Closing GP'
                    }
                }
            },
        });
    }


    $('.client_link_submit').on("click", function (e) {
        const client_select = $('select[name="client"]');
        const clientno = $(this).data('clientno');
        const text = $(this).data('text');
        // Set the value, creating a new option if necessary
        if (client_select.find("option[value='" + clientno + "']").length) {
            client_select.val(clientno).trigger('change');
        } else {
            // Create a DOM Option and pre-select by default
            const newOption = new Option(text, clientno, true, true);
            // Append it to the select
            client_select.append(newOption).trigger('change');
        }
    });

    $('.matter_link_submit').on("click", function (e) {
        const matter_select = $('select[name="matter"]');
        const matterno = $(this).data('matterno');
        const text = $(this).data('text');
        // Set the value, creating a new option if necessary
        if (matter_select.find("option[value='" + matterno + "']").length) {
            matter_select.val(matterno).trigger('change');
        } else {
            // Create a DOM Option and pre-select by default
            const newOption = new Option(text, matterno, true, true);
            // Append it to the select
            matter_select.append(newOption).trigger('change');
        }
    });

    $('#contact_form_submit_button').on("click", function() {
        $('input[id ^="contact_matters_attributes_"][id $="_id"]').each(function (e) {
            //console.log(this.value);
            var val = this.value;
            val = val.split(/[ ,]+/).join(',');
            console.log(val);
            $(this).val(val);
        });
    })
})

$(document).on('turbo:load', function() {

    // Debounce form submissions
    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    const processChange = debounce((e) => e.form.requestSubmit());

    $('.filter_form_input').keyup(function() {
        processChange(this);
    });

    $('.filter_form_select').change(function() {
        processChange(this);
    });


    flatpickr('.date-range-selector', {
        // dateFormat: "d-m-Y",
        disableMobile: "true",
        "plugins": [new rangePlugin({ input: "#end_date"})]
    });

    flatpickr('.date-month-selector', {
        static: true,
        altInput: true,
        plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "Y-m-d", altFormat: "M Y"})]
    });

    $('.tenant_switch_button').on("click", function() {
        $(this).prop('disabled', true);
        $('form.edit_user').submit();
    });

    // initialise select 2 select boxes
    $('.select2-dropdown').select2({
        allowClear: true,
        width: '100%',
        placeholder: 'Select'
    });

    const client_select = $('select[name="client"]');
    client_select.select2({
        allowClear: true,
        width: '100%',
        minimumInputLength: 3,
        placeholder: 'Select',
        ajax: {
            url: '/contacts',
            dataType: 'json',
            data: (params) => ({
                query: params.term?.toLowerCase(),
            }),
            processResults: function (data) {
                return {
                    results: data.map(contact => ({
                        id: contact.ContactID,
                        text: `${contact.Organisation} (${contact.ContactID})`
                    }))
                };
            }
        }
    })

    const client_filter_select = $('select[name="q[ContactID_eq]"]');
    client_filter_select.select2({
        allowClear: true,
        width: '100%',
        minimumInputLength: 3,
        placeholder: 'Select',
        ajax: {
            url: '/contacts',
            dataType: 'json',
            data: (params) => ({
                query: params.term?.toLowerCase(),
            }),
            processResults: function (data) {
                return {
                    results: data.map(contact => ({
                        id: contact.ContactID,
                        text: `${contact.Organisation} (${contact.ContactID})`
                    }))
                };
            }
        }
    })

    const opportunity_client_select = $('select[name="opportunity_workflow[client_id]"]');
    opportunity_client_select.select2({
        allowClear: true,
        width: '100%',
        minimumInputLength: 3,
        placeholder: 'Select',
        ajax: {
            url: '/contacts',
            dataType: 'json',
            data: (params) => ({
                query: params.term?.toLowerCase(),
            }),
            processResults: function (data) {
                return {
                    results: data.map(contact => ({
                        id: contact.ContactID,
                        text: `${contact.Organisation} (${contact.ContactID})`
                    }))
                };
            }
        }
    })

    client_select.change(() => {
        $('select[name="matter"]').val(null).trigger('change');
    })

    opportunity_client_select.change(() => {
        $('select[name="opportunity_workflow[matter_id]"]').val(null).trigger('change');
    })


    const matter_select = $('select[name="matter"]');
    matter_select.select2({
        width: '100%',
        placeholder: 'Select',
        allowClear: true,
        ajax: {
            url: '/matters',
            dataType: 'json',
            data: (params) => ({
                query: params.term?.toLowerCase(),
                client_no: $('select[name="client"]').val()
            }),
            processResults: function (data) {
                return {
                    results: data.map(matter => ({
                        id: matter.MatterNo,
                        text: `${matter.MatterDesc} (${matter.MatterNo})`
                    }))
                };
            }
        }
    })


    const opportunity_matter_select = $('select[name="opportunity_workflow[matter_id]"]');
    opportunity_matter_select.select2({
        width: '100%',
        placeholder: 'Select',
        allowClear: true,
        ajax: {
            url: '/matters',
            dataType: 'json',
            data: (params) => ({
                query: params.term?.toLowerCase(),
                client_no: $('select[name="opportunity_workflow[client_id]"]').val()
            }),
            processResults: function (data) {
                return {
                    results: data.map(matter => ({
                        id: matter.MatterNo,
                        text: `${matter.MatterDesc} (${matter.MatterNo})`
                    }))
                };
            }
        }
    })

    // showing and hiding simple/advanced search bar
    // $('#advanced_search_button').on("click", function() {
    //     $("#simple").hide();
    //     $("#advanced").show();
    //     $("#simple_search_button").show();
    //     $("#advanced_search_button").hide();
    //     $("#show_search_button").hide();
    //     $("#show_search_button2").hide();
    // })

    // $('#simple_search_button').on("click", function() {
    //     $("#simple").show();
    //     $("#advanced").hide();
    //     $("#simple_search_button").hide();
    //     $("#advanced_search_button").show();
    //     $("#show_search_button").hide();
    //     $("#show_search_button2").hide();
    // })

    $('#simple_search_button_ad').on("click", function() {
        $("#simple").show();
        $("#simple_search").show();
        $("#advanced").hide();
        $("#simple_search_button").hide();
        $("#advanced_search_button").show();
        $("#show_search_button").hide();
        $("#show_search_button2").hide();
    })

    $('#show_search_button').on("click", function() {
        $("#simple_search").show();
        $("#advanced_search").show();
        $("#show_search_button").hide();
        $("#show_search_button2").hide();
    })

    $('#show_search_button2').on("click", function() {
        $("#simple_search").hide();
        $("#advanced_search").show();
        $("#show_search_button").hide();
        $("#show_search_button2").hide();
    })



    // contacts form
    $(".formtastic contact").submit(function(e){
        e.preventDefault();
    });

    $('#contact_form_submit_button').on("click", function() {
        $('input[id ^="contact_matters_attributes_"][id $="_id"]').each(function (e) {
            //console.log(this.value);
            var val = this.value;
            val = val.split(/[ ,]+/).join(',');
            console.log(val);
            $(this).val(val);
        });
    })



    // leave workflows section - calculate leave hours when date changed
    let getDays1 = function() {
        var start_date = new Date(document.getElementById('leave_workflow_leave_start_date').value);
        var end_date = new Date(document.getElementById('leave_workflow_leave_end_date').value);
        //Here we will use getTime() function to get the time difference
        var time_difference = end_date.getTime() - start_date.getTime();
        //Here we will divide the above time difference by the no of miliseconds in a day
        var days_difference = time_difference / (1000 * 3600 * 24);
        //alert(days);
        if (end_date != null) {
            if (days_difference >= 0) {
                document.getElementById('leave_workflow_hours_of_leave').value = days_difference * 8 + 8;
            }
        }
    }

    //$('#leave_workflow_leave_start_date').on('change', function() {
        // removing this function, as users were relying on the calculation which didn't account for weekends/holidays.
        // getDays1();
    //})

    //$('#leave_workflow_leave_end_date').on('change', function() {
        // getDays1();
    //})


    // Flash alerts section
    //$(document).ready(function() {
        // $(".btn-close").click(function () {
        //     $("#alert_box").fadeOut("slow");
        // });

        // fade out in 10 seconds if not closed
        // $("#alert_box").fadeIn("fast");
        // setTimeout(function(){
        //     $("#alert_box").fadeOut("slow");
        // },10000)
    //})


    // profile uploads section
    $(document).ready(function(){
        $('input[type="radio"]').click(function(){
            var inputValue = $(this).attr("value");
            // console.log(inputValue);
            const add_folder_button = $("#add_folder_button")
            const add_contact_button = $("#add_contact_button")
            if (inputValue === "customer") {
                $('#rm-level-tree').jstree("deselect_all");
                if ($("#add_folder_button").length) {
                    add_folder_button.fadeOut("fast", function () {
                        add_contact_button.fadeIn("slow");
                    });
                } else {
                    add_contact_button.fadeIn("slow");
                }
                $("#folder_tree_selection").fadeOut("slow", function () {
                    $("#client_selection").fadeIn("slow");
                    $("#matter_selection").fadeIn("slow");
                });
            } else if (inputValue === "folder") {
                $('select[name=\"client\"]').val(null).trigger('change');
                $("#client_selection").fadeOut("slow");
                $("#matter_selection").fadeOut("slow", function () {
                    $("#folder_tree_selection").fadeIn("slow");
                });
                if ($("#add_contact_button").length) {
                    add_contact_button.fadeOut("fast", function () {
                        add_folder_button.fadeIn("fast");
                    });
                } else {
                    add_folder_button.fadeIn("fast");
                }
            }
        });
        // $("#more_options_button").click(function(event){
        //     event.preventDefault();
        //     $("#doctype").fadeIn("slow");
        //     $("#docstatus").fadeIn("slow");
        //     $("#doccomments").fadeIn("slow");
        //     $("#more_options_button").hide();
        // });
    });




    // only run the below on certain pages
    if (document.getElementById("gallery") != null) {
        // file uploads box
        const fileTempl = document.getElementById("file-template"),
            imageTempl = document.getElementById("image-template"),
            empty = document.getElementById("empty"),
            toUpload = document.getElementById("to_upload");

        // use to store pre selected files
        let FILES = {};

        const dataTransfer = new DataTransfer()
        //const fileList = new FileList();
        const dataTransfer1 = new DataTransfer();


        // check if file is of type image and prepend the initialied
        // template to the target element
        function addFile(target, file) {
            const isImage = file.type.match("image.*"),
                objectURL = URL.createObjectURL(file);

            const clone = isImage
                ? imageTempl.content.cloneNode(true)
                : fileTempl.content.cloneNode(true);

            clone.querySelector("h1").textContent = file.name;
            clone.querySelector("li").id = objectURL;
            clone.querySelector(".delete").dataset.target = objectURL;
            clone.querySelector(".size").textContent =
                file.size > 1024
                    ? file.size > 1048576
                        ? Math.round(file.size / 1048576) + "mb"
                        : Math.round(file.size / 1024) + "kb"
                    : file.size + "b";

            isImage &&
            Object.assign(clone.querySelector("img"), {
                src: objectURL,
                alt: file.name
            });

            empty.classList.add("hidden");
            target.prepend(clone);

            FILES[objectURL] = file;

        }

        const gallery = document.getElementById("gallery"),
            overlay = document.getElementById("overlay");
        let fileQty;

        // click the hidden input of type file if the visible button is clicked
        // and capture the selected files
        const hidden = document.getElementById("hidden-input");
        const upload_butt = document.getElementById("upload_button");
        if (upload_butt) {
            upload_butt.onclick = () => hidden.click();
        }
        if (hidden) {
            hidden.onchange = (e) => {
                for (const file of e.target.files) {
                    addFile(gallery, file);
                    dataTransfer.items.add(file)
                }
                ;
                fileQty = gallery.children.length - 1;
                toUpload.innerText = "To Upload (" + fileQty + ")";
            };
        }

        // use to check if a file is being dragged
        const hasFiles = ({dataTransfer: {types = []}}) =>
            types.indexOf("Files") > -1;

        // use to drag dragenter and dragleave events.
        // this is to know if the outermost parent is dragged over
        // without issues due to drag events on its children
        let counter = 0;

        // reset counter and append file to gallery when file is dropped
        window.dropHandler = function (ev) {
            ev.preventDefault();
            for (const file of ev.dataTransfer.files) {
                addFile(gallery, file);
                overlay.classList.remove("draggedover");
                //counter = 0;
                ++counter
            }
            fileQty = gallery.children.length - 1;
            toUpload.innerText = "To Upload (" + fileQty + ")";
        }

        // only react to actual files being dragged
        window.dragEnterHandler = function (e) {
            e.preventDefault();
            if (!hasFiles(e)) {
                return;
            }
            ++counter && overlay.classList.add("draggedover");
        }

        window.dragLeaveHandler = function (e) {
            1 > --counter && overlay.classList.remove("draggedover");
        }

        window.dragOverHandler = function (e) {
            if (hasFiles(e)) {
                e.preventDefault();
            }
        }

        // event delegation to caputre delete events
        // fron the waste buckets in the file preview cards
        if (gallery) {
            gallery.onclick = ({target}) => {
                if (target.classList.contains("delete")) {
                    const ou = target.dataset.target;
                    document.getElementById(ou).remove(ou);
                    gallery.children.length === 1 && empty.classList.remove("hidden");

                    delete FILES[ou];

                    fileQty = gallery.children.length - 1;
                    toUpload.innerText = "To Upload (" + fileQty + ")";
                }
            };
        }


        // print all selected files
        const prof_form_submit = document.getElementById("profile_form_submit_button");
        if (prof_form_submit) {
            prof_form_submit.onclick = () => {
                const fileInput = document.getElementById('hidden-input')

                for (const [key, value] of Object.entries(FILES)) {
                    dataTransfer1.items.add(value);
                }

                fileInput.files = dataTransfer1.files;

                // document.getElementById("profile_form").submit();
                // The above was double submitting the form when using turbo
            };
        }

        const opp_form_submit = document.getElementById("opportunity_form_submit_button");
        if (opp_form_submit) {
            opp_form_submit.onclick = () => {
                const fileInput = document.getElementById('hidden-input')

                for (const [key, value] of Object.entries(FILES)) {
                    dataTransfer1.items.add(value);
                }

                fileInput.files = dataTransfer1.files;

                // document.getElementById("profile_form").submit();
                // The above was double submitting the form when using turbo
            };
        }

        // print all selected files
        const exp_form_submit = document.getElementById("exp_form_submit_button");
        if (exp_form_submit) {
            exp_form_submit.onclick = (e) => {
                const fileInput = document.getElementById('hidden-input')

                for (const [key, value] of Object.entries(FILES)) {
                    dataTransfer1.items.add(value);
                }

                if (Object.entries(FILES).length > 1){
                    Swal.fire("Too Many Files", "You can only submit one file per expense. Please delete excess files and try again.", "error");
                    return;
                }

                fileInput.files = dataTransfer1.files;

                // document.getElementById("profile_form").submit();
                // The above was double submitting the form when using turbo
            };
        }

        // print all selected files
        const packing_slip_form_submit = document.getElementById("packing_slip_form_submit_button");
        if (packing_slip_form_submit) {
            packing_slip_form_submit.onclick = (e) => {
                const fileInput = document.getElementById('hidden-input')

                for (const [key, value] of Object.entries(FILES)) {
                    dataTransfer1.items.add(value);
                }

                if (Object.entries(FILES).length > 1){
                    Swal.fire("Too Many Files", "You can only submit one file per packing slip. Please delete excess files and try again.", "error");
                    return;
                }

                fileInput.files = dataTransfer1.files;

                // document.getElementById("profile_form").submit();
                // The above was double submitting the form when using turbo
            };
        }

    }



    // Invitations
    let i = 1;
    $('#create_new_line').on('click', function () {
        let ua = $('#users_available')[0].innerText
        let users_available = parseInt(ua);
        if (users_available > 1) {
            $('#invitees').append(
                '<tr class="mb-1 p-1 text-xl md:text-base my-2" id="row' + i + '" >' +
                '<td class="m-1 p-1">' +
                '<input type="text" id="name' + i + '" name="user[name][]" class="w-full form-input border-none shadow" required />' +
                '</td>' +

                '<td class="m-1 p-1">' +
                '<input type="text" id="email' + i + '" name="user[email][]" class="w-full form-input border-none shadow" required />' +
                '</td>' +

                '<td class="m-1 p-1">' +
                '<select name="user[admin][]" id="admin' + i + '" class="w-full form-input border-none shadow">' +
                '<option selected value="false">No</option>' +
                '<option value="true">Yes</option>' +
                '</select>' +
                '</td>' +

                '<td class="m-1 p-1">' +
                '<span id="delete_line' + i + '" data-linenumber="' + i + '" title="Remove this line" class="delete_line text-blue-600 hover:text-blue-500"><i class="fas fa-minus-circle mt-2"></i></span>' +
                '</td>' +
                '</tr>'
            );
            i++;
            $('#users_available')[0].innerText = users_available - 1;

        } else {
            Swal.fire({title: "Maxumim Users Reached", text: "You have reached the maximum number of users you can invite. Change your subscription to add more.", icon: "warning"});
        }
    });

    function remove_line(element) {
        $('#row'+$(this).data("linenumber")).remove();
        let users_available = parseInt($('#users_available')[0].innerText);
        $('#users_available')[0].innerText = users_available + 1;
    };

    Rails.delegate(document, '.delete_line', 'click', remove_line);

});
