import Swal from 'sweetalert2';
import Rails from '@rails/ujs';
import $ from "jquery";
import {Turbo} from "@hotwired/turbo-rails";

window.Swal = Swal;

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
    const message = element.getAttribute('data-confirm-swal');
    const text = element.getAttribute('data-text');
    const showCancelButton = element.getAttribute('data-showCancelButton');
    const confirmButtonText = element.getAttribute('data-confirmButtonText');
    const cancelButtonText = element.getAttribute('data-cancelButtonText');
    const icon = element.getAttribute('data-icon');
    const showLoaderOnConfirm = element.getAttribute('data-showLoaderOnConfirm');
    const html = element.getAttribute('data-html');
    const showDenyButton = element.getAttribute('data-showDenyButton');
    const denyButtonText = element.getAttribute('data-denyButtonText');
    const checkInLink = element.getAttribute('data-checkInLink');
    const searchableLink = element.getAttribute('data-searchableLink');
    const showLoaderOnDeny = element.getAttribute('data-showLoaderOnDeny');

    Swal.fire({
        title:             message || 'Are you sure?',
        text:              text || '',
        icon:              icon || 'warning',
        showLoaderOnConfirm: (showLoaderOnConfirm === "true") || false,
        showLoaderOnDeny: (showLoaderOnDeny === "true") || false,
        showCancelButton:  (showCancelButton === 'true') || false,
        confirmButtonText: confirmButtonText || 'Ok',
        cancelButtonText:  cancelButtonText || 'Cancel',
        showDenyButton:  (showDenyButton === 'true') || false,
        denyButtonText: denyButtonText || 'Ok',
        html: html || "",
        preConfirm: function() {
            if (!!element.getAttribute('data-remote')) {
                return new Promise(function(resolve) {
                    let reloadAfterSuccess = !!element.getAttribute('data-reload');

                    $.ajax({
                        method: element.getAttribute('data-method') || 'GET',
                        url: element.getAttribute('href'),
                        dataType: 'json',
                        success: function(result) {
                            Swal.fire('Success!', result.message || '', 'success')
                                .then((_result) => {
                                    if (reloadAfterSuccess) {
                                        window.location.reload();
                                    }
                                    if (message ==="Delete Workflow?") {
                                        element.parentElement.parentElement.remove();
                                    }
                                    if (message === "Checkout Document") {
                                        const href = element.getAttribute('href').replace("checkout", "checkin");
                                        $(element).attr({
                                            "data-confirm-swal": "Document Checked Out",
                                            "data-text": "You currently have this document checked out. Would you like to check it back in?",
                                            "data-confirmbuttontext": "Yes",
                                            "data-showDenyButton": "true",
                                            "href": href
                                        });
                                        $(element).children("p").attr({"class": "ml-2 text-red-700"})
                                        $(element).children("p").html("<i class=\"fas fa-user-clock\" title=\"Check In\"></i>\n")
                                        //$(element).parent().parent().parent().children(":nth-child(3)").children("div:nth-child(1)").children("div > .checked_out_text").fadeIn(500);
                                    } else if (message === "Document Checked Out") {
                                        const href = element.getAttribute('href').replace("checkin", "checkout");
                                        $(element).attr({
                                            "class": "text-blue-700",
                                            "data-confirm-swal": "Checkout Document",
                                            "data-text": "Do you want to check out this document again?",
                                            "data-showDenyButton": "false",
                                            "data-confirmbuttontext": "Yes",
                                            "href": href
                                        });
                                        $(element).children("p").attr({"class": "ml-2"})
                                        $(element).children("p").html("<i class=\"fas fa-user-clock\" title=\"Check Out\"></i>\n")
                                        //hide the "checked out by..." text
                                        $(element).parent().parent().parent().children(":nth-child(3)").children("div:nth-child(1)").children("div > .checked_out_text").hide();
                                    } else if (message === "Archive Document") {
                                        $(element).attr({
                                            "class": "btn-green m-1",
                                            "data-confirm-swal": "Unarchive Document",
                                            "data-text": "Do you want to unarchive this document?",
                                            "data-showDenyButton": "false",
                                            "data-confirmbuttontext": "Yes",
                                        });
                                        $(element).html("Unarchive");
                                    } else if (message === "Unarchive Document") {
                                        $(element).attr({
                                            "class": "btn-red m-1",
                                            "data-confirm-swal": "Archive Document",
                                            "data-text": "Do you want to Archive this document?",
                                            "data-showDenyButton": "false",
                                            "data-confirmbuttontext": "Yes",
                                        });
                                        $(element).html("Archive");
                                    }
                                });
                            resolve();
                        },
                        error: function(xhr) {
                            let title   = 'Error!';
                            let message = 'Something went wrong. Please try again later.';

                            if (element.getAttribute('href').includes("rmlevel")) {
                                message = "Could not delete the folder. Is the folder empty?";
                            }

                            if (xhr.responseJSON && xhr.responseJSON.message) {
                                message = xhr.responseJSON.message;
                            }

                            Swal.fire(title, message,'error');

                            resolve();
                        }
                    });
                });
            } else {
                if (html != null){
                    const expiry_number = Swal.getPopup().querySelector('#expiry_number').value;
                    const expiry_unit = Swal.getPopup().querySelector('#expiry_unit').value;
                    const share_url = Swal.getPopup().querySelector('#share_url').value;
                    let expiry_seconds = 0;
                    if (expiry_unit == "hours"){
                        expiry_seconds = expiry_number * 60;
                    } else if (expiry_unit == "days") {
                        expiry_seconds = expiry_number * 24 * 60;
                    } else if (expiry_unit == "weeks") {
                        expiry_seconds = expiry_number * 24 * 60 * 7;
                    }

                    // alert("Hello! "+expiry_number+" "+expiry_unit+" seconds: "+expiry_seconds);

                    return new Promise(function(resolve) {
                        $.ajax({
                            method: element.getAttribute('data-method') || 'GET',
                            url: share_url+"?expiry="+expiry_seconds,
                            dataType: 'json',
                            success: function (result) {
                                let html_txt = "<input id=\"link\" value=\""+result.doc_url+"\" name=\"doc_url\" class=\"p-2 w-full border\">";
                                Swal.fire('Link Copied to Clipboard',
                                    html_txt,
                                    'success');
                                var copy = $('#link').val();
                                $('#link').select();
                                document.execCommand('copy');
                                resolve();
                            },
                            error: function(xhr) {
                                let title   = 'Error!';
                                let message = 'Something went wrong. Please try again later.';

                                if (xhr.responseJSON && xhr.responseJSON.message) {
                                    message = xhr.responseJSON.message;
                                }

                                Swal.fire(title, message,'error');

                                resolve();
                            }
                        })
                    })
                } else {
                    element.removeAttribute('data-confirm-swal');
                    element.click();
                }
            }
        },
        preDeny: function() {
            if (!!element.getAttribute('data-remote')) {
                return new Promise(function(resolve) {
                    let reloadAfterSuccess = !!element.getAttribute('data-reload');

                    $.ajax({
                        method: element.getAttribute('data-method') || 'GET',
                        url: element.getAttribute('href'),
                        dataType: 'json',
                        success: function(result) {
                            window.location.href = checkInLink,
                            resolve();
                        },
                        error: function(xhr) {
                            let title   = 'Error!';
                            let message = 'Something went wrong. Please try again later.';

                            if (xhr.responseJSON && xhr.responseJSON.message) {
                                message = xhr.responseJSON.message;
                            }

                            Swal.fire(title, message,'error');

                            resolve();
                        }
                    });
                });
            }
            if (searchableLink != null){
                window.location.href = searchableLink;
            }

        }
    }); //.then(result => confirmed(element, result));
};

const confirmed = (element, result) => {
    if (result.value) {
        if (!!element.getAttribute('data-remote')) {
            let reloadAfterSuccess = !!element.getAttribute('data-reload');

            $.ajax({
                method: element.getAttribute('data-method') || 'GET',
                url: element.getAttribute('href'),
                dataType: 'json',
                success: function(result) {
                    Swal.fire('Success!', result.message || '', 'success')
                        .then((_result) => {
                            if (reloadAfterSuccess) {
                                window.location.reload();
                            }
                        });
                },
                error: function(xhr) {
                    let title   = 'Error!';
                    let message = 'Something went wrong. Please try again later.';

                    if (xhr.responseJSON && xhr.responseJSON.message) {
                        message = xhr.responseJSON.message;
                    }

                    Swal.fire(title, message,'error');
                }
            });
        } else {
            element.removeAttribute('data-confirm-swal');
            element.click();
        }
    }
};

const allowAction = (element) => {
    if (element.getAttribute('data-confirm-swal') === null) {
        return true;
    }

    showConfirmationDialog(element);
    return false;
};

function handleConfirm(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element);
    }
}


Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);


function checkClick(element) {
    if (element.target.tagName !== 'svg' && !element.target.classList.contains("matter_link")) {
        Turbo.visit($(this).data("link"));
        // window.location=($(this).data("link"));
    }
}

Rails.delegate(document, 'tr.workflow_row', 'click', checkClick);
