$(() => {
  const select = $('select[name="client"]');
  select.select2({
    allowClear: true,
    width: '100%',
    minimumInputLength: 3,
    placeholder: 'Select',
    ajax: {
      url: '/contacts',
      dataType: 'json',
      data: (params) => ({
        query: params.term?.toLowerCase(),
      }),
      processResults: function (data) {
        return {
          results: data.map(contact => ({
            id: contact.ContactID,
            text: `${contact.Organisation} (${contact.ContactID})`
          }))
        };
      }
    }
  })

  select.change(() => {
    $('select[name="matter"]').val(null).trigger('change');
  })
})
